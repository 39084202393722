(function () {

    'use strict';

    class ServiceOrderWorkflowCtrl {
        constructor(
            $mdPanel, $scope, $compile, moment, ServiceOrderService, $state, mnWebSocket, system, $q, configService, $mdDialog, $element, $translate, frontDeskService, $mdToast) {
            this.$q = $q;
            this.state = $state;
            this.moment = moment;
            this.ws = mnWebSocket;
            this.dialog = $mdDialog;
            this.element = $element;
            this.translate = $translate;
            this.service = ServiceOrderService;
            this.configService = configService;
            this.scope = $scope;
            this.frontDeskService = frontDeskService;
            this.toast = $mdToast;

            this.list = [];
            this.dateFormat = system['date_format'].js;
            this.timeFormat = system['time_format'].js;


            this.filters = {};
            this.dateTimeFormat = system['date_format'].naive;

            this.views = [];
            this.selectedView = {};
            this.columns = this.service.getColumnConfiguration();
            this.subColumns = this.service.getSubColumnConfiguration();
            this.getData = this.getData.bind(this);
            this.onPaginate = this.onPaginate.bind(this);
            this.hideEmptyOrders = false;

        }

        getReasonsSubset() {
            return this.reasons && this.reasons.length ? this.reasons.slice(4) : [];
        }

        static get $inject() {
            return [
                '$mdPanel', '$scope', '$compile', 'moment', 'ServiceOrderService', '$state', 'mnWebSocket', 'system', '$q', 'configService', '$mdDialog', "$element", "$translate", "frontDeskService", "$mdToast"];
        }

        $onInit() {

            this.reasons = [];
            this.reasons = localStorage.getItem("HealthCareServiceOrderReasons") ? localStorage.getItem("HealthCareServiceOrderReasons", "").split(',') : null;
            if (!this.reasons) {
                this.service.getExamReasons().then(value => {
                    this.reasons = value;
                    localStorage.setItem("HealthCareServiceOrderReasons", value);
                });
            }
            this.ticket_status_config = this.service.getTicketStatusConfig();
            this.priorities_config = this.service.getPrioritiesConfig();
            this.ticket_status = ["MISSED", "REJECTED", "ACCEPTED", "COMPLETED", "IN_PROGRESS", "ON_HOLD", "CANCELLED", "SCHEDULED", "RESCHEDULED", "FORCE_COMPLETED"];
            this.filter_primary_ticket_status = ["MISSED", "REJECTED", "COMPLETED", "IN_PROGRESS"];
            this.filter_secondary_ticket_status = ["ON_HOLD", "CANCELLED", "SCHEDULED", "RESCHEDULED", "FORCE_COMPLETED"];

            this.priorities = ["LOW", "NORMAL", "HIGH", "IMMEDIATE"];
            this.options = [5, 15, 20, 50, 100];
            this.paginationLabel = {
                page: this.translate.instant('page'),
                rowsPerPage: this.translate.instant('rowsPerPage'),
                of: this.translate.instant('of')
            }
            this.toggle = false;
            this.handleGlobalSearch = _.mnDelay(this.getData, 400);
            this.query = {
                page: 1,
                search_all: "",
                limit: 20,
                columns: this.columns
            };
            this.activeFilters = {
                priorities: [],
                reasons: [],
                statuses: [],
            };

            this.refreshDataCall();
            this.ws.sub("extranet.HealthCareServiceOrder.notify", 'service-order-workflow', (data) => {
                this.refreshDataCall();
            });
            this.scope.$on("$destroy", () => this.onDestroy())

        }

        onDestroy() {
            this.ws.unsub("extranet.HealthCareServiceOrder.notify", 'service-order-workflow');
        }

        refreshDataCall() {
            this.promise = this.service.getHealthCareServiceOrders(this.query)
                .then(data => {
                    this.list = data['list'];
                    this.total = data['length'];
                    this.hideEmptyOrders = data['hide_empty_orders'];
                    if (this.total < ((this.query.page - 1) * this.query.limit)) {
                        this.query.page = 1;
                    }
                });
        }

        countColumns() {
            return this.service.subColumnConfiguration.length + 1;
        }

        editCarePlan(item, ev) {
            let opts = null;
            if (item) opts = {
                'id': item.id,
                'type': 'plan',
            }
            else opts = {
                'id': null,
                'type': 'plan',
            }

            this.service.getOrderMedicalCarePlan(item.id).then(data => {
                this.state.go("app.visit.medical-care.form", {
                    visitId: item.visit,
                    pId: item.patient.id,
                    'id': data,
                    'type': 'plan',
                    'serviceOrder': item.id
                }).then(data => _.noop);
            })


        }

        entryResume(item, ev) {
            this.frontDeskService.entryResume({
                onlyResume: true,
                entry: null,
                'patient-id': item.patient.id
            }, ev);
        }

        patientFile(item) {
            return this.state.go("app.patient-form", {'patient_id': item.patient.id});
        }

        getData() {
            this.refreshDataCall();
        }

        onPaginate(page, limit) {
            this.query = _.assign(this.query, {page: page, limit: limit});
            this.getData();
        }

        handleToggle(event) {
            this.toggle = !this.toggle;
        }

        addTicket(item, ev) {
            let dialog = _.assign({}, require('specifics/dialogs/service-order-scheduling-dialog/service-order-scheduling-dialog'), {
                multiple: true,
                targetEvent: null,
                locals: {
                    event: {
                        execution_date: this.moment().format(this.dateFormat),
                        start_time: "09:00",
                        end_time: `09:30`,
                        reason: null,
                        patient: item.patient,
                        service_order: item
                    }
                }
            });

            this.dialog.show(dialog).then(_.noop);
        }

        editTicket(order, ticket) {
            this.service.getTicket(ticket.id).then(item => {
                let dialog = _.assign({}, require('specifics/dialogs/service-order-scheduling-dialog/service-order-scheduling-dialog'), {
                    multiple: true,
                    targetEvent: null,
                    locals: {
                        event: _.assignIn(item, {patient: order.patient})
                    }
                });
                this.dialog.show(dialog).then(_.noop);
            });
        }

        cancelTicket(ticket) {
            this.service.cancelTicket(ticket.id).then(data => {

            }, (err) => {
                let toast = this.toast.simple()
                    .textContent(this.translate.instant('service_order_workflow.cancel_delete_warning'))
                    .position("top right")
                    .hideDelay(3000);
                this.toast.show(toast);
            });
        }

        toggleFilter(category, value) {
            var index = this.activeFilters[category].indexOf(value);
            if (index > -1) {
                // Remove filter if it is already active
                this.activeFilters[category].splice(index, 1);
            } else {
                // Add filter if it is not active
                this.activeFilters[category].push(value);
            }
            this.query = _.assign(this.query, {activeFilters: this.activeFilters});
            this.refreshDataCall();

        }

        activeFilterStyle(item, value) {
            return this.activeFilters[item].includes(value) ? {"background-color": "#e0f1ff"} : {};
        }

        payItem(item, ev) {
            this.state.go('app.billing.payment-form', {
                paymentId: null,
                paymentLines: [],
                beneficiaryType: 'P',
                beneficiary: _.get(item, "patient"),
                "paidDoc": {
                    "_module": "extranet.models",
                    "_model": "HealthCareServiceOrder",
                    "_received_amount": _.get(item, 'remaining_amount', 0),
                    "payment_mode": _.get(item, 'payment_infos.payment_mode_type.value', 0),
                    "payer_type": _.get(item, 'payment_infos.payer_type'),
                    "payer": _.get(item, 'payment_infos.payer_type') === 'T' ? _.get(item, "insurance") : (_.get(item, 'payment_infos.payer_type') === 'P' ? _.get(item, "patient") : null),
                }
            });
        }

        InvoiceItem(item, ev) {
            this.state.go("app.billing.invoice-form", {
                invoiceId: null,
                visits: [item],
                beneficiary: item.patient,
                beneficiaryType: 'PATIENT',
                isHealthCareServiceOrder: true,
                invoiceDate: null
            });
        }

        handleItemDelivery(item, service_order) {
            this.service.getTicketConsumables(item.id).then(data => {
                    this.dialog.show(_.assign(require('stock/dialogs/generate-delivery-form-dialog'), {
                        targetEvent: null,
                        locals: {
                            model: {
                                price_mode: 'HT',
                                doc_date: this.moment().format(this.dateFormat),
                                patient: service_order.patient,
                                related_doc: null,
                                service_order_ticket: `#${item.id}`,
                                lines: _.map(data, (l) => {
                                    return {
                                        uid: l.uid,
                                        code: l.code,
                                        article: l.article,
                                        qte: l.qte,
                                        unit_price: _.get(l, 'sale_price', 0),
                                        short_desc: l.article.short_desc,
                                        serialHelperList: l.serial_numbers,
                                        lotHelperList: l.lot_numbers
                                    }
                                })
                            }
                        }
                    })).then(_.noop);
                }
            );
        }

        confirmItem(item, confirm) {
            this.service.updateServiceOrder(item.id, {is_confirmed: confirm}).then(data => {
                this.getData();
            });
        }

    }

    module.exports = ServiceOrderWorkflowCtrl;

})();
